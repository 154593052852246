/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'antd/dist/antd.css';
import 'typeface-rubik'
import 'typeface-montserrat'
import './src/styles/index.sass'
import './src/styles/animations.sass'

export const shouldUpdateScroll = () => {
    document.body.scrollTop = 0
}